import {
  Component,
  ViewChild,
} from '@angular/core';
import { UsersService } from '../_services/users.service';
import { Router } from '@angular/router';
import { MetadataService } from '../_services/metadata.service';

// Step 1: Add the following line...
import { register } from 'swiper/element/bundle';
import { NavigateToAccountService } from '../_services/navigate-account.service';

// Step 2: Add the following line...
register();

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss',
})
export class HomepageComponent {

  public breakpoints = {
    320: {
      slidesPerView: '2.3',
    },
    600: {
      slidesPerView: '3.3',
    },
    900: {
      slidesPerView: '4.2',
    },
  };

  constructor(
    public userService: UsersService,
    public router: Router,
    public metadataService: MetadataService,
    public navigateToAccount : NavigateToAccountService
  ) {}

  ngOnInit(){
    if (!this.userService.list) this.userService.getUsers();
  }
}
