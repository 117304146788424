<section class="hero-section">
  <div class="flex-container hero">
    <img
      class="logo"
      alt="Logo pentru site de escorte love69"
      src="/assets/images/logo-white.png"
    />
    <div class="text">
      <h1>{{ "app.homepageTitle" | transloco }}</h1>
      <p>{{ "app.homepageSubtitle" | transloco }}</p>
    </div>
    <a class="primary" href="escorte" mat-raised-button routerLink="escorte">{{
      "app.exploreNow" | transloco
    }}</a>
  </div>
</section>
<section class="about-us-section">
  <div class="flex-container about-us">
  <h2>{{ "app.aboutUs" | transloco }}</h2>
  <p>{{ "app.homepageAboutUs" | transloco }}</p>
  @if(userService.listPreview){
  <swiper-container
    slides-per-view="2.3"
    grab-cursor="true"
    space-between="12"
    [breakpoints]="breakpoints"
    navigation="true"
    pagination="true"
  >
    @for(user of userService.listPreview;track user.id){
    <swiper-slide>
      <a [routerLink]="user.user.url" [href]="user.user.url" class="user-card">
        <img
          alt="escorte sexy escorte {{
            user.user.userDetails.cityId | cityPipe
          }}"
          [src]="user.profilePhoto.data"
          loading="lazy"
        />
        <div class="image">
          <div class="gradient">
            <div class="content">
              <h3>
                {{ user.user.userDetails.firstName }} ({{
                  metadataService.getYears(user.user.userDetails.dob)
                }})
              </h3>
              <p>
                {{ user.user.userDetails.cityId | cityPipe }}
              </p>
            </div>
          </div>
        </div>
      </a>
    </swiper-slide>
    }
  </swiper-container>
  }

  <a class="primary" href="escorte" routerLink="escorte" mat-raised-button>{{
    "app.discoverNow" | transloco
  }}</a>
</div>
</section>

<section class="services-section">
  <div class="flex-container services">
  <div class="card">
    <h3 class="card-title">
      {{ "app.service1Title" | transloco }}
    </h3>
    <p class="card-subtitle">
      {{ "app.service1Description" | transloco }}
    </p>
  </div>
  <div class="card">
    <h3 class="card-title">
      {{ "app.service2Title" | transloco }}
    </h3>
    <p class="card-subtitle">
      {{ "app.service2Description" | transloco }}
    </p>
  </div>
  <div class="card">
    <h3 class="card-title">
      {{ "app.service4Title" | transloco }}
    </h3>
    <p class="card-subtitle">
      {{ "app.service4Description" | transloco }}
    </p>
  </div>
</div>
</section>
<section class="tutorials-section">
  <div class="flex-container tutorials">
  <h2>{{ "app.steps" | transloco }}</h2>
  <div class="step">
    <div class="number">1.</div>
    <p>{{ "app.tutorial1" | transloco }}</p>
  </div>
  <div class="step">
    <div class="number">2.</div>
    <p>{{ "app.tutorial2" | transloco }}</p>
  </div>
  <div class="step">
    <div class="number">3.</div>
    <p>{{ "app.tutorial3" | transloco }}</p>
  </div>
</div>
</section>
<section class="member-section">
  <div class="flex-container become-member">
  <h2>{{ "app.createAccountTitle" | transloco }}</h2>
  <h3>{{ "app.createAccountSubtitle" | transloco }}</h3>
  <p>{{ "app.createAccountDescription" | transloco }}</p>
  <a [href]="navigateToAccount.url + '/register'" class="secondary" mat-raised-button>
    {{ "app.registerNow" | transloco }}
  </a>
</div>
</section>
<section >
  <div class="flex-container extra-text">
  <p>{{ "app.extraText" | transloco }}</p>
</div>
</section>
